import React from 'react';
import NewRelicSVG from '../../../../components/NewRelicSVG';

const PodcastsIcon = (props) => (
  <NewRelicSVG {...props}>
    <path d="M12,3.179c-4.85,0-8.782,4.099-8.782,9.153c0,2.589,1.031,4.927,2.689,6.593 c0.063,0.062,0.162-0.023,0.107-0.094c-1.2-1.568-1.926-3.611-1.926-5.846c0-4.938,3.542-8.15,7.911-8.15 c4.37,0,7.911,3.213,7.911,8.15c0,2.235-0.727,4.278-1.926,5.846c-0.055,0.07,0.044,0.156,0.107,0.094 c1.658-1.666,2.689-4.004,2.689-6.593C20.781,7.278,16.85,3.179,12,3.179z" />
    <path d="M7.098,12.49c-0.086-0.209-0.312-0.323-0.531-0.27L5.28,12.536c-0.281,0.068-0.428,0.378-0.304,0.64 l3.36,7.078c0.125,0.262,0.458,0.344,0.689,0.168l0.748-0.568c0.165-0.125,0.225-0.346,0.146-0.537L7.098,12.49z" />
    <path d="M4.696,13.119c-0.061-0.126-0.217-0.174-0.338-0.102H4.357c-0.057,0.033-0.092,0.096-0.09,0.162 c0.023,1.211,0.269,2.396,0.712,3.482l0.526,1.082c0.195,0.342,0.412,0.67,0.649,0.98c0.058,0.076,0.068,0.174,0.027,0.258 c0,0.002,0,0.002,0,0.002c-0.023,0.045-0.025,0.098-0.003,0.143l0.454,0.934c0.021,0.045,0.057,0.082,0.101,0.105l1.154,0.625 c0.068,0.037,0.151,0.039,0.221,0.004l0.015-0.008c0.117-0.059,0.165-0.199,0.108-0.316L4.696,13.119z" />
    <path d="M18.719,12.536l-1.287-0.315c-0.219-0.054-0.445,0.061-0.531,0.27l-2.82,6.827 c-0.079,0.191-0.02,0.412,0.146,0.537l0.748,0.568c0.23,0.176,0.564,0.094,0.688-0.168l3.36-7.078 C19.147,12.914,19,12.604,18.719,12.536z" />
    <path d="M18.494,17.746l0.527-1.084c0.442-1.086,0.688-2.271,0.711-3.482c0.001-0.066-0.033-0.129-0.09-0.162 h-0.002c-0.121-0.073-0.277-0.025-0.338,0.102l-3.535,7.352c-0.057,0.117-0.008,0.258,0.109,0.316l0.015,0.008 c0.069,0.035,0.152,0.033,0.222-0.004l1.153-0.625c0.044-0.023,0.079-0.061,0.101-0.105l0.455-0.934 c0.021-0.045,0.019-0.098-0.004-0.143c0,0,0,0,0-0.002c-0.042-0.084-0.031-0.182,0.026-0.258 C18.082,18.414,18.299,18.088,18.494,17.746z" />
  </NewRelicSVG>
);

export default PodcastsIcon;
