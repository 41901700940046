import React from 'react';
import SVG from '@newrelic/gatsby-theme-newrelic/src/components/SVG';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const CheckShieldIcon = ({ className }) => (
  <SVG
    className={className}
    viewBox="0 0 15 19"
    css={css`
      fill: none;
      stroke: none;
    `}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.7926 0.0549888C7.60395 -0.0183296 7.39605 -0.0183296 7.2074 0.0549888L0.54073 2.64592C0.215477 2.77232 0 3.09456 0 3.45457V9.50007C0 12.4766 1.90146 14.8549 3.61791 16.4114C4.49365 17.2056 5.36545 17.8263 6.01667 18.2481C6.34314 18.4596 6.6164 18.6225 6.80995 18.7335C6.90677 18.7891 6.98381 18.8317 7.03777 18.8611C7.06475 18.8758 7.08598 18.8871 7.10104 18.8951L7.11895 18.9046L7.12435 18.9074L7.12681 18.9087C7.36141 19.0303 7.63807 19.0305 7.87268 18.909L7.5 18.1365C7.87268 18.909 7.87386 18.9083 7.87386 18.9083L7.87565 18.9074L7.88105 18.9046L7.89896 18.8951C7.91402 18.8871 7.93525 18.8758 7.96223 18.8611C8.01619 18.8317 8.09323 18.7891 8.19005 18.7335C8.3836 18.6225 8.65686 18.4596 8.98333 18.2481C9.63455 17.8263 10.5064 17.2056 11.3821 16.4114C13.0985 14.8549 15 12.4766 15 9.50007V3.45457C15 3.09456 14.7845 2.77232 14.4593 2.64592L7.7926 0.0549888ZM7.5 18.1365L7.12681 18.9087C7.12681 18.9087 7.12732 18.909 7.5 18.1365ZM11.0243 8.30226C11.4122 7.90916 11.408 7.276 11.0149 6.88808C10.6218 6.50015 9.98865 6.50434 9.60072 6.89745L6.5625 9.9762L5.39928 8.79746C5.01135 8.40436 4.3782 8.40016 3.98509 8.78809C3.59199 9.17602 3.5878 9.80917 3.97572 10.2023L5.85072 12.1023C6.03863 12.2927 6.29499 12.3999 6.5625 12.3999C6.83001 12.3999 7.08637 12.2927 7.27428 12.1023L11.0243 8.30226Z"
      fill="#019CAA"
    />
  </SVG>
);

CheckShieldIcon.propTypes = {
  className: PropTypes.string,
};

export default CheckShieldIcon;
