import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const Logo = ({ className, height }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 450.9 98.51"
    css={css`
      .hexagon {
        fill: var(--system-text-primary-light);
      }
      .brand-color {
        fill: none;
        stroke: #1ce783;
        stroke-miterlimit: 8;
        stroke-width: 3.72px;
      }
      .text-color {
        fill: #dddedf;
        @media (max-width: 760px) {
          fill: var(--system-text-primary-light);
        }
        .dark-mode & {
          fill: #dddedf;
        }
      }
    `}
    className={className}
    height={height || '44px'}
  >
    <path
      className="hexagon"
      d="M108.28,85.31,152.47,60l44.19,25.33v47.85l-44.19,25.33-44.19-25.33Z"
      transform="translate(-108.28 -59.98)"
    />
    <polyline
      className="brand-color"
      points="54.01 61.79 65.25 49.26 54.01 36.72"
    />
    <polyline
      className="brand-color"
      points="33.85 61.79 22.61 49.26 33.85 36.72"
    />
    <line className="brand-color" x1="50.19" y1="26.79" x2="37.66" y2="71.73" />
    <path
      className="text-color"
      d="M226.48,85h15.28c14.6,0,23.84,9.38,23.84,24s-9.24,24-23.84,24H226.48Zm15.28,42.54c10.91,0,17.55-7.3,17.55-18.49s-6.64-18.49-17.62-18.49h-8.91v37Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M270.76,115.58c0-10.45,6.63-18.22,16.14-18.22,9.78,0,14.94,7.44,14.94,17.15v2H276.52c.33,7.37,4.55,12.06,10.65,12.06,4.69,0,8-2.55,9.11-6.7l5.29,1.88c-2.14,6.23-7.43,10-14.4,10C277.46,133.8,270.76,126.37,270.76,115.58Zm6.09-3.68h18.89c-.13-5-2.81-9.38-8.91-9.38C281.74,102.52,277.92,105.94,276.85,111.9Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M318.32,133.07,305.19,98h6.36l9.85,27.67L331.11,98h6.3l-13.13,35Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M340.89,115.58c0-10.45,6.63-18.22,16.14-18.22,9.78,0,14.94,7.44,14.94,17.15v2H346.65c.33,7.37,4.55,12.06,10.65,12.06,4.69,0,8-2.55,9.11-6.7l5.29,1.88c-2.14,6.23-7.43,10-14.4,10C347.59,133.8,340.89,126.37,340.89,115.58ZM347,111.9h18.89c-.13-5-2.81-9.38-8.91-9.38C351.87,102.52,348.06,105.94,347,111.9Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M385.5,133.07h-6V85h6Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M409.41,133.8c-9.58,0-16.28-7.36-16.28-18.22s6.7-18.22,16.28-18.22,16.28,7.37,16.28,18.22S419,133.8,409.41,133.8Zm0-5.15c6.16,0,10.18-5.1,10.18-13.07s-4-13.13-10.18-13.13-10.18,5.1-10.18,13.13S403.25,128.65,409.41,128.65Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M439.35,128v17.14h-6V98h6v5.09a12.4,12.4,0,0,1,10.85-5.76c9.72,0,15.28,8.24,15.28,18.22s-5.56,18.22-15.28,18.22A12.3,12.3,0,0,1,439.35,128Zm-.13-11.12c0,8,4.95,11.65,9.91,11.65,6.57,0,10.25-5.42,10.25-12.93s-3.68-12.92-10.25-12.92c-5,0-9.91,3.55-9.91,11.72Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M470.7,115.58c0-10.45,6.63-18.22,16.14-18.22,9.78,0,14.94,7.44,14.94,17.15v2H476.46c.33,7.37,4.56,12.06,10.65,12.06,4.69,0,8-2.55,9.11-6.7l5.29,1.88c-2.14,6.23-7.43,10-14.4,10C477.4,133.8,470.7,126.37,470.7,115.58Zm6.09-3.68h18.9c-.14-5-2.82-9.38-8.91-9.38C481.69,102.52,477.87,105.94,476.79,111.9Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M527.43,104a13.12,13.12,0,0,0-2.94-.27c-5.09,0-9.18,4-9.18,10.18v19.16h-6V98h6v6A10.25,10.25,0,0,1,525,97.77a21.79,21.79,0,0,1,2.41.13Z"
      transform="translate(-108.28 -59.98)"
    />
    <path
      className="text-color"
      d="M534.87,122.22a11.71,11.71,0,0,0,10.72,6.56c4.08,0,7.7-1.74,7.7-5.56s-3.35-4.35-9.11-5.56-11.79-2.54-11.79-9.71c0-6.23,5.43-10.59,12.86-10.59,6.3,0,11.46,3.08,13.53,7.24L554.5,108a10.27,10.27,0,0,0-9.52-5.69c-4,0-6.69,2-6.69,5.16s3.08,3.88,8,5c6,1.4,12.92,2.61,12.92,10.24,0,6.7-6.09,11.12-13.59,11.12-6.37,0-12.73-2.74-15.41-8.17Z"
      transform="translate(-108.28 -59.98)"
    />
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
};

export default Logo;
