module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/index.js"},
    },{
      plugin: require('../node_modules/@newrelic/gatsby-theme-newrelic/gatsby-browser.js'),
      options: {"plugins":[],"forceTrailingSlashes":true,"layout":{"contentPadding":"2rem","maxWidth":"1700px","sidebarWidth":"340px","component":"/opt/build/repo/src/layouts/index.js","mobileBreakpoint":"760px"},"prism":{"languages":["yaml","sass","scss","java"]},"relatedResources":{"swiftype":{"resultsPath":"/opt/build/repo/src/data/related-pages.json","refetch":false,"engineKey":"Ad9HfGjDw4GRkcmJjUut","limit":5}},"newrelic":{"config":{"instrumentationType":"proAndSPA","accountId":"10956800","trustKey":"1","agentID":"30712246","licenseKey":"NRJS-649173eb1a7b28cd6ab","applicationID":"30712246","beacon":"staging-bam-cell.nr-data.net","errorBeacon":"staging-bam-cell.nr-data.net"}},"segment":{"segmentWriteKey":"noviNOFjASOSPcSEAkwoRxOt0Y1719KD","section":"developer","platform":"developer_pages"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c6adf0dad65cc1d73c93c5e0b1680678"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxHeight":400,"maxWidth":1200,"fit":"inside","linkImagesToOriginal":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":"<svg xmlns=\"http://www.w3.org/2000/svg\" focusable=\"false\" width=\"1rem\" height=\"1rem\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3\"></path><line x1=\"8\" y1=\"12\" x2=\"16\" y2=\"12\"></line></svg>"}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"UA-3047412-33","autoStart":false,"anonymize":true,"controlCookieName":"newrelic-gdpr-consent"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Referrer-Policy: no-referrer-when-downgrade","Content-Security-Policy: frame-ancestors *.newrelic.com *.skilljar.com *.sj-cdn.net"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
