import React from 'react';
import NewRelicSVG from '../../../../components/NewRelicSVG';

const AutomationIcon = (props) => (
  <NewRelicSVG {...props}>
    <g>
      <path
        d="M14.6,14.3l1.4-0.8l-1.9-3.3l-1.4,0.8c-0.7-0.6-1.5-1.1-2.4-1.4V7.9H6.4v1.6C5.5,9.8,4.7,10.3,4,10.9l-1.4-0.8
		l-1.9,3.3L2,14.3c-0.2,1-0.2,1.8,0,2.8l-1.4,0.8l1.9,3.3L4,20.4c0.7,0.6,1.5,1.1,2.4,1.4v1.6h3.9v-1.6c0.9-0.3,1.7-0.8,2.4-1.4
		l1.4,0.8l1.9-3.3l-1.4-0.8C14.8,16.1,14.8,15.2,14.6,14.3z"
      />
      <circle cx="8.3" cy="15.7" r="2.6" />
    </g>
    <g>
      <path
        d="M22.7,4.2l0.8-0.5l-1.2-2l-0.8,0.5c-0.4-0.4-0.9-0.7-1.5-0.8v-1h-2.3v1c-0.5,0.2-1,0.5-1.5,0.8l-0.8-0.5
		l-1.2,2l0.8,0.5c-0.1,0.6-0.1,1.1,0,1.7l-0.8,0.5l1.2,2l0.8-0.5c0.4,0.4,0.9,0.7,1.5,0.8v1h2.3v-1c0.5-0.2,1-0.5,1.5-0.8l0.8,0.5
		l1.2-2l-0.8-0.5C22.9,5.3,22.9,4.8,22.7,4.2z"
      />
      <circle cx="18.9" cy="5.1" r="1.6" />
    </g>
  </NewRelicSVG>
);

export default AutomationIcon;
