exports.components = {
  "component---node-modules-newrelic-gatsby-theme-newrelic-src-pages-404-js": () => import("./../../../node_modules/@newrelic/gatsby-theme-newrelic/src/pages/404.js" /* webpackChunkName: "component---node-modules-newrelic-gatsby-theme-newrelic-src-pages-404-js" */),
  "component---src-pages-all-things-open-js": () => import("./../../../src/pages/all-things-open.js" /* webpackChunkName: "component---src-pages-all-things-open-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-changelog-thank-you-js": () => import("./../../../src/pages/changelog-thank-you.js" /* webpackChunkName: "component---src-pages-changelog-thank-you-js" */),
  "component---src-pages-data-bytes-js": () => import("./../../../src/pages/data-bytes.js" /* webpackChunkName: "component---src-pages-data-bytes-js" */),
  "component---src-pages-developer-events-js": () => import("./../../../src/pages/developer-events.js" /* webpackChunkName: "component---src-pages-developer-events-js" */),
  "component---src-pages-futurehack-2021-js": () => import("./../../../src/pages/futurehack-2021.js" /* webpackChunkName: "component---src-pages-futurehack-2021-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kubecon-europe-2020-js": () => import("./../../../src/pages/kubecon-europe-2020.js" /* webpackChunkName: "component---src-pages-kubecon-europe-2020-js" */),
  "component---src-templates-api-reference-template-js": () => import("./../../../src/templates/ApiReferenceTemplate.js" /* webpackChunkName: "component---src-templates-api-reference-template-js" */),
  "component---src-templates-component-reference-template-js": () => import("./../../../src/templates/ComponentReferenceTemplate.js" /* webpackChunkName: "component---src-templates-component-reference-template-js" */),
  "component---src-templates-embed-page-js": () => import("./../../../src/templates/embedPage.js" /* webpackChunkName: "component---src-templates-embed-page-js" */),
  "component---src-templates-guide-template-js": () => import("./../../../src/templates/GuideTemplate.js" /* webpackChunkName: "component---src-templates-guide-template-js" */),
  "component---src-templates-lab-overview-template-js": () => import("./../../../src/templates/LabOverviewTemplate.js" /* webpackChunkName: "component---src-templates-lab-overview-template-js" */),
  "component---src-templates-overview-template-js": () => import("./../../../src/templates/OverviewTemplate.js" /* webpackChunkName: "component---src-templates-overview-template-js" */)
}

